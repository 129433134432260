import { Card } from "react-bootstrap";
import styles from './ResumeHeadings.module.css';

const ResumeHeadings: React.FC = () => {
    return (
        <>
            {/* Top header */}
            <section className={styles.Header}>
                <Card.Title><h1>Gaz Romo</h1></Card.Title>
                <Card.Subtitle><h2>Front-End Web Developer</h2></Card.Subtitle>
            </section>

            {/* Subheader */}
            <section className={styles.Subheader}>
                <a href="mailto:gazromoweb@gmail.com" target='_blank' rel="noreferrer" style={{color: 'white', fontFamily: 'var(--Russo)'}}>gazromoweb@gmail.com</a>
                <a href="https://gazromo.com/" target='_blank' rel="noreferrer" style={{color: 'white', fontFamily: 'var(--Russo)'}}>gazromo.com</a>
                <span>Battle Ground, WA</span>
                <span>(360) - 409 - 9189</span>
            </section>
        </>
    )
}

export default ResumeHeadings;