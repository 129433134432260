import React from 'react';
import HeaderComponent from '../../Features/HeaderComponent/HeaderComponent';
import InfoSection from '../InfoSection/InfoSection';
import ProjectCard from '../ProjectCard/ProjectCard';
import ToTop from '../../Features/ToTop/ToTop';
import FooterComponent from '../../Features/FooterComponent/FooterComponent';

import styles from './ComponentContainer.module.css';
import { ProjectData } from '../ProjectCard/ProjectData';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


// Main component container holding other components
const ComponentContainer: React.FC = () => {
    // Custom previous arrow on carousel
    const renderCustomPrevArrow = (onClickHandler: () => void) => (
        <button className={styles.customPrevButton} onClick={onClickHandler}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </button>
    );

    // Custom next arrow on carousel
    const renderCustomNextArrow = (onClickHandler: () => void) => (
        <button className={styles.customNextButton} onClick={onClickHandler}>
            <FontAwesomeIcon icon={faChevronRight} />
        </button>
    );

    return (
        <>  
            {/* Header */}
            <HeaderComponent />

            {/* Add padding to account for space taken by header */}
            <div style={{paddingTop: '250px'}} />


            {/* Main article containing page content */}
            <article style={{display: 'flex', flexDirection: 'column'}}>
                {/* Target for home button */}
                <div id="home" style={{marginBottom: '150px'}}/>

                {/* Home section */}
                <section>
                    <InfoSection
                        title="Hey there,"
                        text={`I'm Gaz, a front-end web developer from the beautiful outskirts of Washington. I am an Alum from Washington State University Vancouver, having earned my Bachelor's degree in Digital Technology and Culture, from the Creative Media and Digital Culture department. If you don't know what that is, don't worry! It's effectively equivalent to a Computer Science degree, but with tons of added skills from all over the field added in.\n\nThanks to that, I've dipped my toes in design, video editing, audio, and all manner of Adobe programs. Most importantly, though, my focus (or superpower, as it was affectionately called) is web development. I have a fiery passion for JavaScript, genuinely enjoy using React, and can't wait to explore the next challenge you send my way.`}
                    />
                </section>
                
                {/* Target for about button */}
                <div id="about" style={{marginBottom: '150px'}} />

                {/* About section */}
                <section style={{marginLeft: 'auto'}}>
                    <InfoSection
                        title="So, who are you, really?"
                        text={`I'm a nonbinary programmer (hah) with a passion for art and videogames, and some experience of a little bit of everything in design. Thanks to the contents of my degree, I've had a level of experience in 2D animation, graphic/logo design, sound work, and many applications from the Adobe Creative Suite. I enjoy coding, especially when it comes to JavaScript, so I figure this is the perfect fit for a career path, right?`}
                       
                    />
                </section>
                

                {/* Target for projects button */}
                <div id="projects" style={{marginBottom: '150px'}} />

                {/* Carousel mapping over projects from ProjectData */}
                <Carousel
                    showThumbs={false}
                    className={styles.CarouselStyles}
                    renderArrowPrev={renderCustomPrevArrow}
                    renderArrowNext={renderCustomNextArrow}
                    swipeable={false}
                >
                    {ProjectData.map((proj) => (
                        <div key={proj.title}>
                            <ProjectCard
                                title={proj.title}
                                subtitle={proj.subtitle}
                                text={proj.text}
                                src={proj.src}
                                alt={proj.alt}
                                href={proj.href}
                                desc={proj.desc}
                                more={proj.more}
                            />
                        </div>
                    ))}
                </Carousel>
            </article>


            {/* Footer */}
            <FooterComponent />


            {/* ToTop button */}
            <ToTop />
        </>
    )
}

export default ComponentContainer;