import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setFeedbackMessage } from './FeedbackFormSlice';
import styles from './FeedbackForm.module.css';

const FeedbackForm: React.FC = () => {
    // Feedback state
    const [feedback, setFeedback] = useState('');

    // Establish dispatch
    const dispatch = useDispatch();

    // Handle submit button press
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(setFeedbackMessage(feedback));

        const email = 'gazromoweb@gmail.com';
        const subject = 'Feedback';
        const body = feedback;

        const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoUrl, "_blank");
    };
    
    return (
        <Form onSubmit={handleSubmit} className={styles.Feedback}>
            <Form.Group controlId="feedback">
            <Form.Label><h3>Feedback</h3></Form.Label>
            <p>(Clicking submit will take you to your default email app and fill in the body with your typed feedback)</p>

            <Form.Control
                as="textarea"
                rows={3}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
            />

            </Form.Group>

            <br/>

            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    );
};

export default FeedbackForm;
