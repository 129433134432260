import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../Features/HeaderComponent/HeaderComponent';
import FooterComponent from '../../Features/FooterComponent/FooterComponent';
import styles from './ResumeContainer.module.css';
import { Card, Spinner, Modal, Button } from 'react-bootstrap';
import ToTop from '../../Features/ToTop/ToTop';
import ResumeHeadings from '../../Resume/ResumeHeadings/ResumeHeadings';
import ResumeSidebar from '../../Resume/ResumeSidebar/ResumeSidebar';
import ResumeWorks from '../../Resume/ResumeWorks/ResumeWorks';

const ResumeContainer: React.FC = () => {
    // States for browser width
    const [width, setWidth] = useState<boolean>(window.innerWidth >= 768);

    useEffect(() => {
        const checkResume = () => {
            window.innerWidth >= 768 ? setWidth(true) : setWidth(false);
        }

        window.addEventListener("resize", checkResume);

        return () => window.removeEventListener("resize", checkResume);
    }, [])

    
    // Image state and image loading state
    const [image, setImage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Load resume image
    useEffect(() => {
        // Async function to retrieve image data and return
        const loadImage = async () => {
            try {
                const imageModule = await import(`../../../img/resume.jpg`);
                setImage(imageModule.default);
            } catch (error) {
                console.error(`Failed to load image: ${error}`);
            } finally {
                setIsLoading(false);
            }
        };

        loadImage();
    }, []);


    // Modal show state
    const [show, setShow] = useState(false);

    // Handle closing and showing of Modal
    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    return (
        <>
            {/* Site header */}
            <HeaderComponent />

            {/* Add padding to account for space taken by header */}
            <div style={{paddingTop: '250px'}} id='resume' />
            

            {/* Check screen size to determine if resume should be image or part of the page */}
            {isLoading && <Spinner />}
            {width ?
                <Card className={styles.Resume}>
                    {/* Headers */}
                    <ResumeHeadings />

                    <section className={styles.ResumeBody}>
                        {/* Sidebar */}
                        <ResumeSidebar />

                        {/* Main content (Experience) */}
                        <ResumeWorks />
                    </section>
                </Card>
                    :
                    
                <>
                    <h3 className='text-center'>Click / Tap the image below to expand it into focus.</h3>
                    <img src={image} alt="Resume" className={styles.ResumeImg} onClick={handleShow} />
                    <Modal show={show} onHide={handleClose} centered>
                        <Modal.Body>
                            <img src={image} alt="Resume" className={styles.ResumeImgModal} />
                        </Modal.Body>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal>
                </>
            }
            

            {/* ToTop button */}
            <ToTop />


            {/* Site footer */}
            <FooterComponent />
        </>
    )
}

export default ResumeContainer;