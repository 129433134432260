import FeedbackForm from '../FeedbackForm/FeedbackForm';
import styles from './FooterComponent.module.css';

// Footer component
const FooterComponent: React.FC = () => {
    return (
        <footer className={styles.FooterFull}>
            {/* Main footer content */}
            <div className={styles.FooterComponent}>
                <section>
                    <h3>Contact</h3>
                    <p>(360) - 409 - 9189</p>
                    <a href="mailto:gazromoweb@gmail.com" target="_blank" rel="noreferrer" >gazromoweb@gmail.com</a>
                </section>

                <section>
                    <h3>Socials</h3>
                    <a href="https://twitter.com/gazromoweb" target="_blank" rel="noreferrer" >Twitter</a><br/>
                    <a href="https://instagram.com/gazromo" target="_blank" rel="noreferrer" >Instagram</a><br/>
                    <a href="https://reddit.com/user/gazromo" target="_blank" rel="noreferrer" >Reddit</a><br/>
                </section>
            </div>
            
            {/* Feedback form */}
            <FeedbackForm />
        </footer>
    )
}

export default FooterComponent;