import { Button } from "react-bootstrap";
import styles from "./ToTop.module.css";

// ToTop button to allow returning to the top of the screen
const ToTop: React.FC = () => {
    // Scroll to the top on button click
    const handleTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    return (
        // Button itself
        <Button
            variant='primary'
            className={styles.ToTop}
            onClick={handleTop}
        >
            ^
        </Button>
    )
};

export default ToTop;