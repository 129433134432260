// Exported Typescript interface for the Project Data
export interface Project {
    title: string;
    subtitle: string;
    text: string;
    src: string;
    desc: string;
    more: string;
    href: string;
    alt: string;
};

// Array of objects containing all the necessary information on projects, exported
export const ProjectData: Project[] = [
    {
        title: 'DATA ENTRY: PORTAL',
        subtitle: "Web Developer, 2023",
        text: 'Part of the web development team in the re-creation of a 1986 interactive game/novel created by Rob Swigart, re-envisioned in a VR environment',
        src: 'data-entry-portal.jpg',
        desc: "Worked with web dev team to prototype for the website in Adobe XD\nDeveloped HTML5, CSS3, and JavaScript code for the website used to present and promote the game\nCollaborated with the design team to ensure all was within the team's and client's specifications and vision",
        more: "For the DATA ENTRY: PORTAL archival site, I spent months working with a web development team of five (including myself) to create the wireframe, prototype, and site itself. We collaborated with the design team to generate a concrete color palette for the site and project as a whole, and with the game team and content development team to acquire media to embed in the site. The project as a whole was around ~155 hours of work time, most of which was pertaining to the website itself, and with the rest of the time dedicated to communication with the team. My primary role in this site was structuring the HTML and CSS to be mobile-first in its design, and handling most of the JavaScript code, especially for the device detection, parallax animation, and button functionality (for the credits page). However, I additionally provided support with writing HTML and CSS code at length to assist the team.",
        href: "https://dtc-wsuv.org/projects/data-entry-portal",
        alt: "DATA ENTRY: PORTAL site image",
    },
    {
        title: "Portfolio",
        subtitle: "Web Developer, 2023",
        text: "Created the portfolio you may or may not be reading this on using React and its many libraries",
        src: "portfolio.jpg",
        desc: "Coded for the portfolio in React Typescript, with CSS modules and React Bootstrap for styling\nCreated elements using many React components, making use of their reusability\nUsed numerous React and JavaScript libraries, such as React-Redux, Redux, Redux Toolkit, Node.js, Bootstrap, and React Bootstrap",
        more: "This portfolio is the one you may very well be viewing right now, although the best and most interesting part about web development is that you could be viewing a version of it that has been iterated over hundreds of times. This is, of course, true of most any programming method, but I find it incredible either way. It was very interesting and an honestly fun experience to redo my entire portfolio using the utility of React.js, and now that I have it set up with such a powerful and reusable framework, I can easily make edits to it at any time. Adding new projects is as simple as adding a single object to an array. I believe this has strongly built up my React skills, and also served to better teach me Typescript.",
        href: "https://gazromo.com/",
        alt: "Portfolio site image",
    },
    {
        title: 'Work-Break Timer',
        subtitle: "Web Developer, 2023",
        text: 'A React app made to help productivity',
        src: 'work-timer.jpg',
        desc: "Coded for the website primarily in React.js / JSX, with CSS modules and inline Bootstrap styling\nCreated elements using React and managed store with Redux Toolkit\nManaged state using Redux, React-Redux, and Redux Toolkit",
        more: "This React app is made to help productivity, allowing the user to set the length of a work interval and a break interval, then alternating between the two. As it switches intervals, it will notify the user by playing a short text-to-speech audio clip, letting them know it is either time to take a break or time to work. This helps keep the user accountable and productive with an audio/visual feedback loop.",
        href: "https://gazromo.com/work-timer",
        alt: "Work Break Timer site image",
    },
    {
        title: 'Calculator',
        subtitle: "Web Developer, 2023",
        text: 'A React calculator app',
        src: 'calculator.jpg',
        desc: "Coded for the website primarily in React.js / JSX, with some React Bootstrap\nCreated and styled elements using React\nManaged state using Redux, React-Redux, and Redux Toolkit to evaluate different functions",
        more: "This is a small standard calculator, made as a React app using many of the same tools as the other small React projects on display. Those tools are primarily React, Redux, React-Redux, React Bootstrap, Bootstrap, and Redux Toolkit. This calculator can handle fairly large numbers, capping out at about 20 digits, with your basic, run-of-the-mill math functions (add, subtract, multiply, divide), and a backspace and clear button for good measure. It works perfectly as a little calculator for small calculations, even though most computers already have a scientific calculator in them anyway, it's simply a fun little experiment that was a great time to make.",
        href: "https://gazromo.com/calculator",
        alt: "Calculator site image",
    },
    {
        title: 'Drum Kit',
        subtitle: "Web Developer, 2023",
        text: 'A keyboard and mouse drum kit made with React and Redux',
        src: 'drum-kit.jpg',
        desc: "Coded for the website primarily in React.js / JSX, with some React Bootstrap\nCreated and styled elements using React\nManaged state using Redux, React-Redux, and Redux Toolkit",
        more: "This little drum kit is a React App, also made for the similar purpose of testing my ability to use React, Redux, React-Redux, React Bootstrap, and React Toolkit. I have only been learning these tools for quite a short time, but I feel I am picking them up quite quickly, and am enjoying my time doing so. React is certainly a very powerful tool, and in the future, you may even be reading this on a portfolio created in React. The possibilities are limitless!",
        href: "https://gazromo.com/drum-machine",
        alt: "Drum kit site image",
    },
    {
        title: 'Canvas RPG',
        subtitle: "Web Developer, 2023",
        text: 'Short RPG game made in HTML Canvas',
        src: 'canvas-rpg.jpg',
        desc: "Styled the site using HTML and CSS\nApplied nearly all functionality with heavy use of JavaScript to manipulate the HTML Canvas elements",
        more: "This Canvas RPG is a short RPG of sorts made using HTML canvas elements as 'screens,' so to speak. The canvases are then accessed and modified at length using a large amount of JavaScript code to check which 'screen' the user is on. The way this is achieved is by checking the background color of the current 'screen' and setting the next 'screens' according. In actuality, there is no new screen, but instead the code checks where the user moved and changes all necessary canvas properties as needed to create the appearance of entering a new screen. Additionally, there are interactable NPCs and 'boss fights,' where the player plays Rock, Paper, Scissors against a basic AI.",
        href: "https://gazromo.com/canvas-rpg",
        alt: "Canvas RPG site image",
    },
    {
        title: 'React Quote Machine',
        subtitle: "Web Developer, 2023",
        text: 'Simple site that uses React.js to display a random quote from an array',
        src: 'quote-machine.jpg',
        desc: "Coded for the website primarily in React.js / JSX, with some CSS3\nCreated almost all elements using a React App rather than HTML5 alone\nUsed Bootstrap to apply basic styling without needing heavy use of CSS3",
        more: "This site is a simple project made using React.js to help me get acquainted with the library. It is created almost entirely using a React App, with some minimal use of CSS3 and a basic HTML5 template provided by the React library for use with the application. It also predominantly uses Bootstrap for styling, with some of the previously mentioned CSS3 being used when needed. This project was made as part of a challenge for freeCodeCamp's online course teaching React.js.",
        href: "https://gazromo.com/quote-machine",
        alt: "Quote Machine site image",
    },
    {
        title: 'Soul Power',
        subtitle: "Web Developer and Logo Designer, 2022",
        text: 'Fictional company website selling an athletic product',
        src: 'soul-power.jpg',
        desc: "Coded for the website in HTML5, CSS3, and jQuery\nDrafted and designed the logo for the company in Adobe Illustrator\nWorked with group to ensure the logo, website design, and font choice matched the Brand Guide and Style Guide",
        more: "Soul Power is a fictional fitness company advertising a product that would generate electricity via kinetic energy in the form of a resistance workout suit. The user would wear the suit while exercising, providing additional resistance and also generating an amount of power to charge a battery within the suit that would allow for charging a phone or the like. For this project, I was the sole web developer (or 'soul' web developer, if you will). I also designed the logo. This was a small team of three, in which one of my team members handled the creation of a style and brand guide for us to follow, another team member created the graphics of the suit, and I myself created the site using HTML5, CSS3, and jQuery.",
        href: "https://gazromo.com/soul-power",
        alt: "Soul Power site image",
    },
    {
        title: 'Restiveness Hill',
        subtitle: "Web Developer, 2023",
        text: 'Website that creates randomly generated Shel Silverstein poems by splicing lines together, placing them on the page',
        src: 'restiveness-hill.jpg',
        desc: "Coded in HTML5, CSS3\nCreated all functionality using JavaScript code\nRandomized by accessing data from JavaScript arrays",
        more: "Restiveness Hill is an anagram of Shel Silverstein, and also a fun little website where you can generate nonsensical poems at the press of a button. The JavaScript code takes several Shel Silverstein poems, shuffles their lines together, and creates a large block of nonsense (including shuffled titles) with the fanfare of a silly sound effect. Additionally, the user can click red lines of text and reshuffle that specific line in a roulette / slot machine style, where it cycles rapidly and stops when clicked again.",
        href: "https://gazromo.com/restiveness-hill",
        alt: "Restiveness Hill site image",
    },
    {
        title: 'Entertainerainbow (Entertainer Rainbow)',
        subtitle: "Web Developer, 2023",
        text: 'Website that plays the opening notes to the entertainer on a button piano, pressing the keys automatically at the correct times',
        src: 'entertainer-rainbow.jpg',
        desc: "Coded the site in HTML5, CSS3, and JavaScript\nUsed JavaScript extensively to create nearly all elements on the page and to manipulate the buttons using video data",
        more: "Entertainer Rainbow (or Entertainerainbow for short-ish) is a small site that makes use of JavaScript's ability to edit sound and video elements. At the press of a button, it starts playing the opening to The Entertainer on piano, using frame data of the video to know when to activate the buttons on screen. The buttons are not just static either -- they are proper buttons the user can interact with, and that JavaScript is itself interacting with, not just mimicking the function of. The colors of the keys are also randomized, minus the black keys.",
        href: "https://gazromo.com/entertainer-rainbow",
        alt: "Entertainer Rainbow site image",
    },
    {
        title: 'State Flowers',
        subtitle: "Web Developer, 2023",
        text: 'Website that uses the Mapbox API to display an interactive map of the state flowers of the United States, providing information about each.',
        src: 'state-flowers.jpg',
        desc: "Used the Mapbox API to display a map in HTML\nStyled the map in CSS3 and provided functionality with extensive use of JavaScript\nUtilized JavaScript arrays to access large amounts of data",
        more: "If you ever feel like you're lost, you can always turn to a map. In this case, though, this map will be no help for directions -- it's a map of the state flowers of the United States. This site uses Mapbox API to generate a map and place markers. Each marker is meticulously placed by myself using JavaScript and coordinates to position it over the relevant state. I also used many JavaScript arrays and nested arrays in order to change the contents of the information sections to allow the user to learn a few fun facts about each flower and when it came to be designated as the state's floral symbol.",
        href: "https://gazromo.com/state-flowers",
        alt: "State Flowers site image",
    },
    {
        title: 'Dialogue',
        subtitle: "Web Developer, 2022",
        text: 'Website that explores a narrative of a mysterious disappearance using the interface of a computer desktop',
        src: 'dialogue.jpg',
        desc: "Coded the site in HTML5, CSS3, and used jQuery\nDesigned the visual elements in Adobe Illustrator and Adobe Photoshop",
        more: "Full transparency here: this site was my first ever foray into JavaScript, in the form of jQuery in this case. For my first ever attempt at using it, I believe it is somewhat impressive in its own right. This site tells a short, abstract / vague narrative of a mysterious disappearance using the medium of a fake computer operating system. The jQuery within animates 'computer windows' on the screen and allows the user to navigate the various files and windows within. As it was my first ever attempt at using JavaScript of any form, the site itself is admittedly rough around the edges, but as a proof of concept, I believe it is quite respectable. It even checks to see if you've read every email to create a special surprise, along with checking the current date.",
        href: "https://gazromo.com/dialogue",
        alt: "Dialogue site image",
    },
];
