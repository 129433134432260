import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Typescript interface
interface HeaderState {
    scrollPos: number;
}

// Typescript initial state
const initialState: HeaderState = {
    scrollPos: 0,
};

// Header component slice for reducers export
const headerComponentSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setScrollPos: (state, action: PayloadAction<number>) => {
            state.scrollPos = action.payload;
        },
    },
});

export const { setScrollPos } = headerComponentSlice.actions;
export default headerComponentSlice.reducer;
