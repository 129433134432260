import styles from './ResumeSidebar.module.css';

const ResumeSidebar: React.FC = () => {
    return (
        <>
                <section className={styles.ResumeSidebar}>
                    <section>
                        <h3 className={styles.Heading}>Education</h3>

                        <p style={{fontWeight: 'bolder', marginTop: 0}} className={styles.ResumeText}>
                            B.A. in Digital Technology &amp; Culture
                        </p>

                        <p className={styles.ResumeText}>
                            <em>The CMDC of the Department of Digital Technology &amp; Culture</em>
                        </p>

                        <p className={styles.ResumeText}>
                            <em>Washington State Vancouver, May 2023</em>
                        </p>
                        
                        <p style={{fontWeight: 'bolder', marginTop: 0}} className={styles.ResumeText}>
                            Minor in Mathematics
                        </p>

                        <p className={styles.ResumeText}>
                            <em>Washington State Vancouver, August 2023</em>
                        </p>
                    </section>

                    <section>
                        <h3 className={styles.Heading}>Skills</h3>
                        
                        <ul>
                            <li>Web Development</li>
                            <li>UX / UI</li>
                            <li>Front-End Design</li>
                            <li>Multimedia Design</li>
                            <li>Graphic Design</li>
                            <li>2D Animation</li>
                        </ul>
                    </section>

                    <section>
                        <h3 className={styles.Heading}>Tools</h3>
                        
                        <ul>
                            <li>JavaScript / jQuery / Typescript</li>
                            <li>React / Redux</li>
                            <li>HTML5 / CSS3</li>
                            <li>Ruby</li>
                            <li>Adobe XD</li>
                            <li>Github</li>
                            <li>Visual Studio Code</li>
                            <li>Adobe Illustrator</li>
                            <li>Slack / Zoom</li>
                        </ul>
                    </section>

                    <section>
                        <h3 className={styles.Heading}>Hobbies</h3>

                        <ul>
                            <li>Digital Art / Animation</li>
                            <li>Reading</li>
                            <li>Baking</li>
                            <li>Video Games</li>
                        </ul>
                    </section>
                </section>
        </>
    )
}

export default ResumeSidebar;