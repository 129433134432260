import React, { useState, useEffect } from 'react';
import styles from './ProjectCard.module.css';
import { Card, Button } from 'react-bootstrap';
import { Project } from './ProjectData';

// Card component that maps over ProjectData to show projects
const ProjectCard: React.FC<Project> = ({ title, subtitle, text, src, desc, more, href, alt }) => {
    // Parse descriptions to get bullet points
    const lines = desc.split('\n');
    const renderedDesc = lines.map((line, index) => (
        <li key={index}>{line}</li>
    ));

    // useStates for images and image loading state
    const [image, setImage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    // useEffect to load images properly
    useEffect(() => {
        // Async function to retrieve image data and return
        const loadImage = async (imageName: string) => {
            try {
                const imageModule = await import(`../../../img/${imageName}`);
                setImage(imageModule.default);
            } catch (error) {
                console.error(`Failed to load image: ${error}`);
            } finally {
                setIsLoading(false);
            }
        };

        loadImage(src);
    }, [src]);


    // useState to check if "read more" information is expanded
    const [isExpanded, setIsExpanded] = useState(false);

    // Set isExpanded on "read more" click
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsExpanded(!isExpanded);
    };

    const clickLink = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, href: string) => {
        window.open(href, '_blank');
    }

    // If expanded, "more" content is in a p. If not, "desc" content is in an unordered list
    const descriptionContent = isExpanded ? (
        <p>{more}</p>
        ) : (
        <ul>{renderedDesc}</ul>
    );

    // Switch button text based on if information is expanded
    const moreDesc = isExpanded ? (
        'Read Less' 
        ) : (
            'Read More'
    )


    return (
        // Card holding project content
        <Card className={`${styles.ProjectCard} bg-light p-3`}>
            {/* Project site image */}
            <Card.Img variant="top" alt={alt} src={image} />

            {/* Body of the card containing information about the site */}
            <Card.Body>
                <Card.Title className={styles.CardTitle}>{title}</Card.Title>
                <Card.Subtitle className={styles.CardSubtitle}>{subtitle}</Card.Subtitle>
                <Card.Text>{text}<br/><br/></Card.Text>
                <div style={{textAlign: 'left'}}>{descriptionContent}</div>
                <br/>

                {/* Buttons for visiting the site and for seeing more information */}
                <Button variant="primary" className={styles.Visit} onClick={(e) => clickLink(e, href)}>Visit</Button>
                <Button variant="secondary" className={styles.ReadMore} onClick={handleClick}>{moreDesc}</Button>
            </Card.Body>
        </Card>
    )
}

export default ProjectCard;