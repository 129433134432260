import React, { useEffect } from 'react';
import styles from './HeaderComponent.module.css';
import { Nav, Navbar } from 'react-bootstrap';
import { setScrollPos } from './HeaderComponentSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../App/store';
import { Link, useLocation } from 'react-router-dom';

const HeaderComponent: React.FC = () => {
    // Establish dispatch
    const dispatch = useDispatch();

    // Establish location
    const location = useLocation();

    // Scroll position state
    const scrollPos: number = useSelector((state: RootState) => state.header.scrollPos);

    // useEffect checking scroll position
    useEffect(() => {
        // Get scroll position
        const handleScroll = (): void => {
            const pos = window.scrollY || document.documentElement.scrollTop;
            dispatch(setScrollPos(pos));
        }

        // Scroll event listener
        window.addEventListener('scroll', handleScroll);


        // Return cleanup function
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);


    return (
        // Enclosing header
        <header>
            {/* Navbar */}
            <Navbar bg="dark" variant="dark" expand="md" className={`${styles.Header}`}>
                {/* Check scroll position to determine if brand should be visible (reduces clutter) */}
                {scrollPos < 150 && <Navbar.Brand as={Link} to="/" className={`text-wrap ${styles.HeaderF1}`}><h1>Gaz Romo</h1><h2>Front-end Web Developer</h2></Navbar.Brand>}
                
                {/* Navbar toggle button */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" className={`${styles.NavToggle} ${styles.NavToggleMargin}`} />

                {/* Navbar collapsing menu */}
                <Navbar.Collapse id="basic-navbar-nav" className={styles.NavCollapse}>
                    {/* Nav menu */}
                    {location.pathname === '/resume' ?
                        <Nav className={styles.NavParent}>
                            <Nav.Link as={Link} to="/" className={styles.NavChild}>Home</Nav.Link>
                            <Nav.Link as={Link} to="/" className={styles.NavChild}>About</Nav.Link>
                            <Nav.Link as={Link} to="/" className={styles.NavChild}>Projects</Nav.Link>
                            <Nav.Link href="#resume" className={styles.NavChild}>Resume</Nav.Link>
                        </Nav>
                        :
                        <Nav className={styles.NavParent}>
                            <Nav.Link href="#home" className={styles.NavChild}>Home</Nav.Link>
                            <Nav.Link href="#about" className={styles.NavChild}>About</Nav.Link>
                            <Nav.Link href="#projects" className={styles.NavChild}>Projects</Nav.Link>
                            <Nav.Link as={Link} to="/resume" className={styles.NavChild}>Resume</Nav.Link>
                        </Nav>
                    }
                    
                </Navbar.Collapse>
            </Navbar>
        </header>
    )
}

export default HeaderComponent;