import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './InfoSection.module.css';

// Typescript interface
interface InfoSectionProps {
    title: string;
    text: string;
}

// Reusable component for home and about sections
const InfoSection: React.FC<InfoSectionProps> = ({ title, text }) => {
    // Parse passed in text to render line breaks properly
    const lines = text.split('\n');
    const renderedText = lines.map((line, index) => (
        <span key={index}>{line}<br/></span>
    ));

    return (
        <>
            {/* Card containing information */}
            <Card className={`${styles.InfoSection} bg-light p-3`}>
                <Card.Body>
                    <Card.Title><h3>{title}</h3></Card.Title>
                    <Card.Text>{renderedText}</Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export default InfoSection;