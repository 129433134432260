import React from 'react';
import styles from './ResumeProject.module.css';

interface ResumeProjectInterface {
    title: string;
    subtitle: string;
    text: string;
    href: string;
    desc: string;
}

const ResumeProject: React.FC<ResumeProjectInterface> = ({ title, subtitle, text, href, desc }) => {
    // Parse descriptions to get bullet points
    const lines = desc.split('\n');
    const renderedDesc = lines.map((line, index) => (
        <li key={index}>{line}</li>
    ));

    return (
        <section className={styles.Project}>
            <p style={{fontWeight: 'bolder'}}>{title}</p>
            <p>{subtitle}</p>
            <p>{text}</p>

            <a target="_blank" className={styles.URL} href={href} rel="noreferrer">{href.slice(8, href.length)}</a>

            <ul>{renderedDesc}</ul>
            <br/>
        </section>
    )
}

export default ResumeProject;