import { configureStore } from "@reduxjs/toolkit";
import HeaderComponentSlice from "../Components/Features/HeaderComponent/HeaderComponentSlice";
import FeedbackFormSlice from "../Components/Features/FeedbackForm/FeedbackFormSlice";

// Redux toolkit store definition w/ Header slice reducer
export const store = configureStore({
    reducer: {
        header: HeaderComponentSlice,
        feedback: FeedbackFormSlice,
    }
})

export type RootState = ReturnType<typeof store.getState>;