import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ComponentContainer from '../Components/Home/ComponentContainer/ComponentContainer';
import ResumeContainer from '../Components/Resume/ResumeContainer/ResumeContainer';

// Main App function that's rendered to the page
const App = () => {
	return (
		<Router>
			<Routes>
				{/* Home page */}
				<Route path="/" element={<ComponentContainer />} />
				<Route path="/resume" element={<ResumeContainer />} />
			</Routes>
		</Router>
	);
}

export default App;
