import React from "react"
import { ProjectData } from "../../Home/ProjectCard/ProjectData";
import ResumeProject from '../ResumeProject/ResumeProject';
import styles from './ResumeWorks.module.css';

const ResumeWorks: React.FC = () => {
    // First 4 projects
    const ResProjects = ProjectData.slice(0, 4);

    return (
        <>
            {/* Main content (Experience) */}
            <section className={styles.ResumeWorks}>
                <p style={{borderBottom: "5px dashed var(--purple4)", paddingBottom: 15}}><em>My passion for coding gives me endless energy for this!</em></p>
                <h3 style={{marginTop: -15}} className={styles.Heading}>Experience</h3>

                {ResProjects.map((proj) => (
                    <div key={proj.title}>
                        <ResumeProject
                            title={proj.title}
                            subtitle={proj.subtitle}
                            text={proj.text}
                            href={proj.href}
                            desc={proj.desc}
                        />
                    </div>
                ))}
            </section>
        </>
    )
}

export default ResumeWorks;