import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Typescript interface
interface FeedbackState {
    message: string;    
}

// Initial state
const initialState: FeedbackState = {
    message: '',    
};

// Slice with reducer
const FeedbackFormSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        setFeedbackMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload;
        },
    },
});

export const { setFeedbackMessage } = FeedbackFormSlice.actions;
export default FeedbackFormSlice.reducer;